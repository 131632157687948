/* You can add global styles to this file, and also import other style files */

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "../src/assets/styles/abstract/global";
@import "../src/assets/styles/abstract/variables";
@import "../src/assets/styles/components/tables";
@import "../src/assets/styles/components/button";
@import "../src/assets/styles/components/global";
@import "../src/assets/styles/base/global";

html, body { height: 100%; }
body { margin: 0; font-family: "Poppins", sans-serif !important;  background-color: #f0f2f6 !important;}

body::-webkit-scrollbar {
    width: .4em;
  }
   
  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  // **tracking
.tracking {
  .track-information {
    background-color: #fff;
    display: block;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 20px;
    margin: 10px auto;
    max-width: 70%;
    @include mobile-phones {
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      height: auto;
    }
    .tracking-details {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      width: 100%;
      padding: 10px 0;
      @include mobile-phones{
        width: 100%;
        display: flex;
        flex-direction: column;
      //   justify-content: flex-start;
      //  align-items: flex-start;
      }
      div {
        margin-bottom: 15px; 
        padding: 15px;
        text-align: center;
        h3{
          font-size: 12px;
          color: #212529;
        }
        p{
          font-weight: 600;
          font-size: 13.2px;

        }
        @include mobile-phones {
          
          div {
            margin-bottom: 10px;
            padding: 10px;
            
  
           
            h3{
              font-size: 12px;
            }
            p{
              font-weight: 600;
              font-size: 13.2px;
    
            }
          }
      }
      }
  }
  }
  & .tracking-caption {
    font-weight: 600;
    font-size: 1.9rem;
    margin-bottom: 10px;
    margin-top: 45px;
    text-align: center;
  }
  &-info-section {
    width: 40%;
    padding: 20px;

    @include mobile-phones {
      width: 100%;
    }
  }

  &-intro-text {
    font-family: "Poppins", sans-serif;
    font-size: 11.5px;
    @include mobile-phones {
    }
  }

  &-input {
    & .track-button {
      margin-left: 20px;
      border-radius: 7px;
      background-color: $newNav;

      @include mobile-phones {
        margin-left: 10px;
        margin-top: 10px;
      }
    }
    @include mobile-phones {
      text-align: center;
    }
  }
}
.top-heading {
  font-size: 1.8rem;
  padding: 20px;
}

.input-container,
.button-container {
  & input {
    width: 100%;
  }
  & button {
    width: 100%;
    margin-left: 0 !important;
    border-radius: 4px !important;
  }
}

.button-container {
  margin-top: 20px;
}
.tracking-formation {
  & .tracking-intro-text {
    font-weight: 600 !important;
    font-family: "poppins";
  }
}
.print-button {
  background-color: #f5f6f8;
  margin-right: 8px;
  color: #27ae60;
}


// cooo
.transaction {
  // &-content {
  // }
  &-options {
    padding: 20px;
  }
  &-input-content {
    @include flex;
    align-items: center;
    @include mobile-phones {
      @include flexDirection(column);
      align-items: flex-start;
    }
  }
  &-input-container {
    padding: 10px;
    width: 250px;
    @include mobile-phones {
      width: 100%;
      margin-bottom: 20px;
      padding: 0;
    }

    & label {
      font-size: 1.2rem;
      margin-bottom: 15px;
    }
    & .input-class {
      color: opacifyColor($primary-color, 0.6);
      width: 100%;
      padding: 10px !important ;
      font-weight: 500;
      @include mobile-phones {
        padding: 20px !important;
      }
    }
    & .button {
      border-radius: 4px;
      width: 200px;
      font-weight: 500;
      background-color: $newNav;
      margin-top: 30px;
      @include mobile-phones {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}

input[type="date"] {
  display: block;

  /* Solution 1 */
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;

  /* Solution 2 */
  /* min-width: 96%; */
}
input[type="date"] {
  font-weight: 600;
  color: black;
  @include mobile-phones {
    padding: 20px;
  }
}
.transaction-table-content-header {
  padding: 10px;
  & button {
    margin: 5px;
    & i {
      padding-right: 10px;
    }
  }
}

.table-button-options {
  @include mobile-phones {
    display: none;
  }
}

.transaction-options {
  @include mobile-phones {
    margin-bottom: 20px;
  }
}


.transaction-input-content {
  font-size: 1rem;
}
.track {
  // display: flex;
  // flex-direction: column;
  i {
    padding: 3px;
  }
  .track-text {
    font-size: 1rem;
  }
  .track-button {
    background-color: #f5f6f8;
    padding-right: 3px;
    i {
      color: #e30510;
    }
  }
  .print-button {
    background-color: #f5f6f8;
    margin-right: 8px;
    color: #27ae60;
  }
}
#trackBtn{
  cursor: pointer;
}
.table-container{
  overflow-x: auto;
  width: 100%;
  table-layout: auto; 
  width: 100%;
  max-width: 100%;
  table-layout: auto; 
  text-align: left !important;
  #table_header,  #table_cell{
    font-size: 11.9px !important;
    text-align: left !important;
    padding: 10px 8px !important;
    white-space: normal; 
  word-wrap: break-word; 
  overflow-wrap: break-word; 
  min-width: 90px;
  }
}
