@import "../abstract/responsiveness";

//general table display

.table-buttons {
  @include flex;
  justify-content: flex-end;
  @include mobile-phones {
    display: none;
  }
}
.function-button {
  min-height: 50px;
  margin-top: 25px;
  border-radius: 6px;
  margin-bottom: 20px;
  background-color: #fff;
  @include mobile-phones {
    display: none;
  }
}


table {
  @include mobile-phones {
    // width: 100%;
  }
}
