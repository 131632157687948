
//function to add 
@function opacifyColor($color,$value){
    @return transparentize($color,$value) 

}


@for $i from 1 through 10{
    .flex-#{$i}{
        flex:$i;
    }
}