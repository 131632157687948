@import "../abstract/variables";

h1 {
  font-size: 2.1rem;
}
h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.9rem;
  font-family: $font-poppins;
}
p {
  font-size: 1.4rem;
}
a {
  line-height: 25px;
}
table {
  
  font-family: $font-poppins;
}

.intro-text {
  font-weight: 500;
}
.custom-text{
  font-size: 10px;
}

.page-text {
  margin-bottom: 40px;
  & h2 {
    font-size: 1.6rem;
    font-weight: 400;
  }
}
.table-button {
  margin-right: 10px;
  margin-top: 10px;
  margin-left: 5px;
  width: auto;
  min-width: 7%;
  padding: 8px;
  border: none;
  background-color: #f7f7f7;
  color: #4e4e4e;
  border-radius: 2px;

  i {
    margin-right: 10px;
  }
}
