@mixin flex {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display:-webkit-flex;    
}

@mixin  flexDirection($direction) {
    flex-direction: $direction;
}

@mixin centralize {
    margin: 0 auto;
}


@mixin flexProps($alignItems,$justifyContent, $alignSelf) {
    justify-content: $justifyContent;
    align-items: $alignItems;
    align-self: $alignSelf;
}

@mixin borderStyle($width,$style,$color){
    border:$width $style $color
}

@mixin card-color($color){
    background-color: $color;
}


@mixin hoverEffect($bgColor){
    background-color:$bgColor;
    transition: all 200ms ease-in .3s ;
}