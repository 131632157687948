@import "../abstract/variables";
@import "../abstract/mixins";

.button-submit {
  background-color: $thickTomato;
  &:hover {
    @include hoverEffect($thickerTomato);
  }
}
.button-md {
  width: 80px;
  border-radius: 7px;
  font-size: 1.1rem;
}

// .copy-button {
//   background-color: #f7f7f7;
// }
// .print-button {
//   background-color: #f7f7f7;
// }
// .excel-button {
//   background-color: #f7f7f7;
// }
// .pdf-button {
//   background-color: #f7f7f7;
// }
