//colors

$whiteColor: #fff;
$tomatoColor: #eb9492;
$thickTomato: #c55f5d;
$thickerTomato: #d34644;
$lightorange: #ff726f;
$blackColor: #000;
$newNav: #020607;
$deepGrey: rgb(88, 86, 86);
$bgImage: #f0f2f6;
$lightblue: #89939d;

$lightDark: #a2a6a7;
$abandoned: #f2994a;
$success: #27ae60;
$failed: #eb5757;
$boxColor1: #e30510;
$boxColor2: #a2a6a7;
$greenColor: #378a30d5;
$formColor: #3457dc;
$primary-color: #272727;
$secondary-color: #ff652f;
$lighterSecondary: #e68967;
$cards: #ffffff;
//fonts

$font-poppins: "Poppins", sans-serif;
 