@import "../abstract/responsiveness";

.display-card {
  border-radius: 10px;
  background-color: #fff;
  margin: 10px;
  min-height: 100px;
  @include mobile-phones {
    margin: 0;
  }
  
}

.display-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  min-height: 60px;
  background-color: #ffffff;
  
}

.table-content {
  min-height: 400px;
  border-radius: 6px;
  background-color: #fff;
  
}
.mat-form-field {
  font-size: 14px;
  width: 30%;
  @include mobile-phones {
    width: 80%;
  }

  & .filter-line {
    background: #fff !important;
    width: 70%;
  }
}


.table-filter {
  @include flex;
  align-self: center;
  justify-content: space-between;
  float: right;
  min-height: 20px;
  margin: 10px 25px 10px 0 !important;
  background-color: #eeeeee;
  width: 40%;
  border-radius: 20px;
  padding: 5px;
  @include forLandscape {
   width: 25%;
  }
  @include mobile-phones {
    float: left;
    width: 100%;
  }
  input {
    flex-grow: 1;
    width: 100%;
    border: none;
    border-radius: 20px;
    padding: 8px 10px;
    outline: none;
    border-radius: 20px;
    background-color: none;
  }
  i {
    font-size: 1.4rem;
    padding: 0 10px;
    color: #555;
    display: flex;
    align-items: center;
  
  }
}
