@import "../abstract/variables";
@import "../abstract/functions";
@import "../abstract/responsiveness";

* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-size: 12px;
  background-color: white !important;
  @include mobile-phones {
    font-size: 10px;
  }
}
//I added  Change angular material table header cell


#table_header{
  color: #585656;
  background-color: #cccdcd;
  border: none;
  outline: none;
}
.even-row{
  background-color:#ffffff;
  transition: background-color 0.3s;
}
.odd-row{
  background-color: #f0f2f6;
  transition: background-color 0.3s;
}


//input styles
input,
select {
  border: none;
  background-color: $bgImage;
  padding: 15px;
  outline: none;
  &:focus,
  :hover {
    border: none;
  }
}

button.icon {
  color: $primary-color;
  font-size: 13px;
  font-weight: 300;
  & i {
    padding-right: 10px;
  }
}

button {
  padding: 10px;
  border: none;
  color: $whiteColor;
  outline: none;
  width: 150px;

  &:active {
    border: none;
    outline: none;
  }
}
table {
  width: 100%;
  font-family: $font-poppins !important;
}

.mat-form-field {
  font-size: 14px;
  width: 100%;
  box-shadow: none !important;

  input {
    background: #fff !important;
  }
}

.mat-header-row {
  border: none !important;

  @include mobile-phones {
  }
}
.mat-cell:before {
  @include mobile-phones {
    //content: attr(data-label);
    text-transform: uppercase;
    display: block;
    margin-right: 25px;
    font-size: 1.05rem;
    float: left !important;
    //text-transform: uppercase;
    font-weight: normal;
    //   margin-left: -20px;
  }
}


.mat-table tbody {
  background-color: #f0f2f6 !important;
  @include mobile-phones {
    background-color: #f0f2f6 !important;
  }
}
.mat-header-cell {
  background-color: #cdcccc;
  width: 100%;
  font-size: 1.29rem;
  font-weight: 700;
  color: rgba(56, 55, 55, 0.67);
  justify-content: center;
  color: $deepGrey;
  border: none !important;
  padding: 3px;

  @include mobile-phones {
    font-size: 1rem;
    font-weight: 700;
  }
}

.mat-cell {
  width: 100%;
  font-weight: 500;
  text-align: center;
  justify-content: center;
  color: rgba(44, 43, 43, 0.67);
  text-align: center;
  font-size: 1.3rem;
  &:first-child {
    padding-left: 0;
  }
  @include mobile-phones {
    font-size: 1rem;
    width: 100%;
    text-align: center;
    width: 100px;
  }
}
.mat-row {
  font-weight: 400 !important;
  border: none !important;
  cursor: pointer;
  transition: all 100ms ease-in 0.1s;
  font-family: $font-poppins !important;
  padding: 10px;
  @include mobile-phones {
    // background-color: #fff !important;
    padding: 3px;
    //border-bottom: 1px solid #ddd !important;
    //margin-bottom: 10px;
  }
}
.mat-table .mat-row:nth-child(even) {
  background-color: $bgImage;
  transition: all 100ms ease-in 0.3s;
}
.mat-table .mat-row:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

.centers {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.spinner-grow {
  width: 100px;
  height: 100px;
}
.overlays {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.286);
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.flex {
  display: flex;
}

.table {
  @include mobile-phones {
    overflow: scroll;
  }
}

.display-tbl {
  @include mobile-phones {
    width: 100%;
  }
}
.password-message {
  text-align: center;
  position: absolute;
  background: #f5f5f5;
  width: 30%;
  min-height: 100px;

  padding: 10px;
  font-size: 1.7rem;
  top: 35%;
  left: 43%;
  @include mobile-phones {
    left: 17%;
    top: 30%;
    width: 70%;
    border-radius: 15px;
  }
  .message {
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 20px;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.9rem;
    @include mobile-phones {
      margin-top: 20px;
      margin-bottom: 10px;
      padding: 10px;
      line-height: 2.9rem;
    }
  }
  button {
    background: green;
    border-radius: 7px;
    width: 30%;
    margin-bottom: 30px;
  }
}
.overlay {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.286);
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}
