@import '../abstract/global';

*{
    margin: 0;
    padding: 0;
}

body{
    
    background-color: #f0f2f6 !important;
}

.container-class{
    width: 100%;
    height: 100%;
}

.container-content{
    width: 95%;
    margin: 0 auto;

    @include mobile-phones{
        width: 100%;
    }
}