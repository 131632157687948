@import "../abstract/responsiveness";

.display-card {
  border-radius: 10px;
  background-color: #fff;
  margin: 10px;
  min-height: 100px;
  @include mobile-phones {
    margin: 0;
  }
}

.display-icon-container {
  border-radius: 10px;
  min-height: 60px;
  background-color: #fff;
}

.table-content {
  min-height: 400px;
  border-radius: 6px;
  background-color: #fff;
  
}
.mat-form-field {
  font-size: 14px;
  width: 30%;
  @include mobile-phones {
    width: 80%;
  }

  & .filter-line {
    background: #fff !important;
    width: 70%;
  }
}
//tiny cards

.table-filter {
  @include flex;
  align-self: flex-end;
  // justify-content: flex-end;
  float: right;
  min-height: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 25px !important;
  background-color: #eeeeee;
  width: 20%;
  border-radius: 20px;
  @include mobile-phones {
    float: left;
    width: 35%;
  }
  input {
    // width: 20%;
    border-radius: 20px;
    padding: 10px;
    background-color: none;
  }
  i {
    font-size: 1.6rem;
    padding-top: 13px;
    padding-left: 10px;
  }
}
